@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');
@import url('https://fonts.cdnfonts.com/css/cabinet-grotesk');
*,
*::before,
*::after {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Cabinet grotesk', sans-serif;
  /* font-family: 'Russo One', sans-serif; */
}

:root {
  /* --nav-height: 4rem; */
  --clr-light-green: white;
  --clr-black-shade-bg: #0D0D0D;
}


.clr-green {
  /* color: var(--clr-light-green); */
}
.heroBackGround {
  background-image: url("../src/assets/images/bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


.clr-white {
  color: #fff;
}

.letter-spacing-1 {
  letter-spacing: 1px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input {
  -moz-appearance: textfield;
}