@import url(https://fonts.cdnfonts.com/css/cabinet-grotesk);
@import url(https://fonts.googleapis.com/css2?family=Russo+One&display=swap);
@import url(https://fonts.cdnfonts.com/css/cabinet-grotesk);
body {
  margin: 0;
  font-family: 'Clash Grotesk', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  overflow-x: hidden;
  /* overflow: hidden; */
}

button {
  cursor: pointer;
}

code {
  font-family: 'Clash Grotesk', sans-serif !important;
}

.text-gray {
  color: #6f6868;
}

.text-bold {
  font-weight: 600;
}

.toast-red {
  background-color: red;
}

.toast {
  color: rgb(27, 27, 27);
  background: white;
  border-radius: .5rem;
  display: flex;
  flex-direction: column;
  font-weight: 600;

}

.toast-btn {
  background: rgba(197, 90, 239);
  color: white;
  border: none;
  padding: .35rem .75rem;
  border-radius: .25rem;
  margin-top: .5rem;
  cursor: pointer;
  font-weight: 600;
  font-size: clamp(.75rem, 1vw, .8rem);
}

.toast-btn:hover {
  background: rgba(197, 90, 239, .8);
}

.toast-btn.close {
  color: black;
  background: #9490902b;
}

.toast-btn.close:hover {
  background: #b7afaf27;
}

.toast-btn-group {
  grid-gap: .5rem;
  gap: .5rem;
  display: flex;
  justify-content: flex-end;
}
*,
*::before,
*::after {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Cabinet grotesk', sans-serif;
  /* font-family: 'Russo One', sans-serif; */
}

:root {
  /* --nav-height: 4rem; */
  --clr-light-green: white;
  --clr-black-shade-bg: #0D0D0D;
}


.clr-green {
  /* color: var(--clr-light-green); */
}
.heroBackGround {
  background-image: url(/static/media/bg.88136483.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


.clr-white {
  color: #fff;
}

.letter-spacing-1 {
  letter-spacing: 1px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input {
  -moz-appearance: textfield;
}
.bg-image {
  inset: 0;
  z-index: -1;
  width: 100vw;
  opacity: 0.8;
  /* height: 100vh; */
  position: fixed;
  background: url(/static/media/bg.88136483.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.amount {
  width: 90%;
  display: flex !important;
  margin: auto;
}

.amount>button {
  padding: 0px;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-container {
  --min-h: 350px;
  width: 100%;
  /* border: 20px solid rebeccapurple; */
  display: flex;
  padding: 10rem 3rem 5rem 3rem;
  position: relative;
  align-items: flex-end;
  justify-content: center;
  background: transparent;
  /* min-height: 100vh;      */
}

.token-container-wrapper {
  width: min(410px, 100%);
  min-height: var(--min-h);
  height: auto;
  position: relative;
  /* border: 20px solid white; */
}

.upper-box-group {
  /* border: 10px solid white; */
  top: -100px;
  width: 260%;
  left: 50%;
  translate: -50% 0;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: space-between;
  /* border: 10px solid orange; */
}

.left-box-group,
.right-box-group {
  display: grid;
  --distance: -80%;
  position: relative;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  justify-content: center;
  align-items: center;
  /* border: 10px solid red; */
}

.left-box-group {
  left: var(--distance);
  height: 100%;
  position: absolute;
}

.right-box-group {
  height: 100%;
  right: var(--distance);
  position: absolute;
}

.left-box-group .card::before,
.right-box-group .card::before {
  content: "";
  height: 2px;
  width: 100%;
  position: absolute;
  background: var(--clr-light-green);
}

.left-box-group .card::before {
  left: 100%;
}

.right-box-group .card::before {
  right: 100%;
}

.token-container {
  width: 100%;
  min-height: var(--min-h);
  height: auto;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  /* padding: 1.5em; */
  display: flex;
  text-align: center;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  background: var(--clr-black-shade-bg);
  border: 1px solid pink;
  border-radius: 20px;
  margin-top: 2.5rem;
  border: double 2px transparent;
  background-image: linear-gradient(black, black),
    linear-gradient(120deg, #ff3bff, #ecbfbf, #5c24ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.pol-creater-wrapper {
  width: 100%;
  height: auto;
  /* padding: 1rem 0rem; */
  position: relative;
  /* border-radius: 0 40px; */
  background: var(--clr-light-green);
  border: 1px solid var(--clr-black-shade-bg);
  margin-bottom: 2rem;
  background-color: #00000046;
}

.payment-option-container {
  grid-gap: 1em;
  gap: 1em;
  padding: 1em 2em;
  display: flex;
  flex-direction: column;
  /* border: 5px solid red; */
  width: 100%;
}

.payment-option-container input {
  width: 100%;
  padding: 0.5rem;
  text-align: center;
  border-radius: 1rem;
  background: transparent;
  border: 1px solid #000;
  font-size: 20px;
  font-family: Inter;
  color: rgba(18, 18, 18, 0.88);
}

.payment-option-container input::-webkit-input-placeholder {
  color: black;
  font-weight: 400;
}

.payment-option-container input::placeholder {
  color: black;
  font-weight: 400;
}

.payment-opt-btn-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 10px solid black; */
  /* background-color: linear-gradient(120deg, #ff3bff, #ecbfbf, #5c24ff); */
}

.gradientButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 10px solid black; */

  /* background-color: linear-gradient(120deg, #ff3bff, #ecbfbf, #5c24ff); */
}

.gradientButton .button {
  width: 100%;
  border: 2px solid black;
}

.gradient-border {
  border: 1px solid pink;
  border-radius: 20px;
  border: double 2px transparent;
  background-image: linear-gradient(black, black),
    linear-gradient(120deg, #ff3bff, #ecbfbf, #5c24ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  /* height: 20px; */
}

.white-border {
  border: 2px solid white;
  border-radius: 20px;
}

.payment-opt-btn-group button {
  cursor: pointer;
  border: none;
  padding: 0.1rem;
  font-size: 0.7rem;
  height: 35px;
  /* background-color: linear-gradient(120deg, #ff3bff, #ecbfbf, #5c24ff); */
  /* border-radius: 0 10px; */
  color: white;
  /* color: var(--clr-light-green);
  background: var(--clr-black-shade-bg);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
}

.connect-wallet-btn {
  /* margin-top: 10px; */
  justify-self: flex-end;
}

.wrapper-container {
  top: 50%;
  left: 50%;
  width: 102%;
  height: 102%;
  display: flex;
  padding: 1.5rem;
  position: absolute;
  align-items: center;
  border-radius: 0 40px;
  justify-content: center;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border: 1px solid var(--clr-black-shade-bg);
}

.connect-wallet {
  background: transparent !important;
  border: 2px solid white !important;
  border-radius: 20px !important;
  width: 100% !important;
}

.connect-wallet button {
  width: 100% !important;
  background: transparent !important;
  text-align: center !important;
}

.pol-creater {
  width: 100%;
  height: 100%;
  border-radius: 0 40px;
  background: var(--clr-black-shade-bg);
}

.pol-creater .container {
  grid-gap: 0.5rem;
  gap: 0.5rem;
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.pol-creater .container input {
  font-size: 0.8rem;
  padding: 0.5rem 1rem;
  border-radius: 0 30px;
  background: transparent;
  color: var(--clr-light-green);
  border: 1px solid var(--clr-light-green);
}

.pol-creater .container input::-webkit-input-placeholder {
  font-size: 0.6rem;
  text-align: center;
  color: var(--clr-light-green);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.pol-creater .container input::placeholder {
  font-size: 0.6rem;
  text-align: center;
  color: var(--clr-light-green);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

/* Only for mob */

.tab-pannel {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 12px;
  border: 1px solid #CBD7FF;
}

.tab {
  color: white;
  border: none;
  font-weight: 600;
  border-radius: 12px;
  padding: 1rem 2rem;
  background: transparent;
  font-size: clamp(1rem, 1.5vw, 1rem);
}

.tab.btn-1 {
  color: black;
  background: #FFCBFD;
}

.tab.btn-2 {
  color: black;
  background: #b892ef;
}

.data-info {
  color: white;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: .5rem;
  gap: .5rem;
  border: 1px solid #CBD7FF;
  border-radius: 12px;
  background: black;
}

.row {
  font-weight: 600;
  display: grid;
  font-size: clamp(0.8rem, 1.5vw, 1rem);
  grid-template-columns: 1fr 1fr;
}

.pol-creater .container .confirm-btn {
  padding: 0.5rem;
  font-size: 0.7rem;
  background: var(--clr-light-green);
}

.mob {
  display: none;
}

.btn-group-mob {
  display: none;
}



@media only screen and (max-width: 998px) {
  .btn-group-desk {
    display: none;
  }

  .btn-group-mob {
    display: grid;
  }

  .mob {
    display: grid;
  }

  .gradient-border {
    background-image: initial;
    border: 1px solid #CBD7FF !important;
  }

  .border-tab-1 {
    border-color: #B892EF !important;
  }

  .upper-box-group,
  .left-box-group,
  .right-box-group {
    display: none;
  }

  .mob-claim-btn {
    margin-top: 1rem;
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
  }


  .infoPara {
    line-height: 30px !important;
  }

  .connect-wallet,
  .payment-option-container input {
    border-radius: 12px !important;
  }

  .main-container {
    padding: 3rem 1rem;
    align-items: center;
  }

  .token-container-wrapper {
    grid-gap: 1rem;
    gap: 1rem;
    display: grid;
  }

  .token-container {
    margin-top: 0;
  }

  .payment-option-container {
    padding: 5% 7%;
  }


  .token-container>button,
  .token-container>input {
    border-radius: 12px !important;
  }
}

@media only screen and (max-width: 768px) {

  .main-container {
    align-items: center;
    padding: 1.5rem;
  }

}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 350px) {}


/* new CSS for mobile */
@media only screen and (max-width: 998px) {}
.card {
  position: relative;
  /* padding-top: 24px; */
  /* padding-bottom: 21px; */
  /* padding-left: 72px; */
  /* padding-right: 72px; */
  width: 220px;
  height: 100px;
  display: flex;
  white-space: nowrap;
  align-items: center;
  /* border-radius: 24px; */
  /* border-radius: 0px 25px; */
  flex-direction: column;
  justify-content: center;
  /* border: 1px solid #FB54F3; */
  background: var(--clr-black-shade-bg);
  border: 1px solid pink;
  border-radius: 20px;
  border: double 2px transparent;
  background-image: linear-gradient(black, black),
    linear-gradient(120deg, #ff3bff, #ecbfbf, #5c24ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.upper-box-group .card {
  width: 340px;
  /* Add any other specific styles you need */
}

/* child */
.border-wrapper {
  overflow: hidden;
  position: relative;
  /* Clip the gradient border within the border radius */
}

/* parent */
.border-wrapper {



  /* padding: 2rem; */
  /* Ensure the entire border area is covered by the gradient */
  /* Optional: Add padding to make space for content */
}

.card-heading {
  font-size: 1.1rem;
  color: #fb54f3;
}

.card-value {
  font-size: 1.3rem;
  color: white;
  text-align: center;
}

.card .content {
  grid-gap: 5px;
  gap: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 998px) {
  .card {
    width: 170px;
  }

  .card-heading {
    font-size: 0.8rem;
  }

  .card-value {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 425px) {
  .card {
    width: 150px;
  }

  .card-heading {
    font-size: 0.8rem;
  }

  .card-value {
    font-size: 0.9rem;
  }
}
.header {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  position: -webkit-sticky;
  position: sticky;
  background-color: #00000072;
  /* background:transparent; */
  /* border: 10px solid white; */
}

.nav {
  width: 95%;
  margin: auto;
  display: flex;
  padding: 1rem;
  align-items: center;
  height: var(--nav-height);
  justify-content: space-between;
}

.logo {
  grid-gap: 1rem;
  gap: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logo img {
  width: 110px;
}

.logo-image {
  width: 45px;
}

.logo-text {
  color: var(--clr-light-green);
  text-align: center;
  font-family: Russo One;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 5.95px;
  text-transform: uppercase;
}

/* 
.menu {
     gap: 1rem;
     display: flex;
     align-items: center;
     color: var(--clr-light-green);
} */

.menu button {
  cursor: pointer !important;
  color: black !important;
  /* border-radius: 0px 20px !important; */
  border-radius: 20px !important;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: linear-gradient(120deg, #ff3bff, #ecbfbf, #5c24ff) !important;
}

.hamberger-menu,
.close-menu-btn {
  border: none;
  cursor: pointer;
  width: -webkit-max-content;
  width: max-content;
  align-items: center;
  height: -webkit-min-content;
  height: min-content;
  background: transparent;
  outline: var(--clr-light-green);
}

.hamberger-menu {
  display: none;
  font-size: 1.8rem;
  color: var(--clr-light-green);
}

#hamberger-checkbox {
  opacity: 0;
  -webkit-appearance: none;
          appearance: none;
}

.menu-option {
  display: none;
  top: 100%;
  width: 140px;
  height: 90px;
  padding: 1.4rem;
  position: absolute;
  border-radius: 20px;
  background: #000;
  align-items: flex-end;
}

.menu-option .close-menu-btn {
  top: 10px;
  right: 10px;
  position: absolute;
  font-size: 1.5rem;
  color: var(--clr-light-green);
}

.left-nav:has(#hamberger-checkbox:checked) .menu-option {
  display: flex;
}

@media screen and (max-width: 668px) {
  .nav {
    width: 100%;
  }

  .left-nav {
    display: flex;
    align-items: center;
  }

  .logo {
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }

  .logo-image {
    width: 30px;
  }

  .logo-text {
    letter-spacing: 1px;
    font-size: 1rem;
  }

  .home {
    display: none;
  }

  .menu {
    font-size: 0.9rem !important;
  }

  .hamberger-menu {
    display: flex;
  }
}

@media screen and (max-width: 425px) {
  .nav {
    padding: 0.5rem;
    padding-inline: 1rem;
  }

  .logo {
    grid-gap: 0;
    gap: 0;
  }

  .logo-text {
    font-size: 0.9rem;
    white-space: nowrap;
    line-height: normal;
    letter-spacing: 1px;
  }

  .menu button {
    white-space: nowrap !important;
    height: -webkit-max-content !important;
    height: max-content !important;
    padding: 0.5rem !important;
    border-radius: 0px 15px !important;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background-color: var(--clr-light-green) !important;
  }
}
.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0;
  width: 100%;
  max-width: 1100px;
  height: 100%;
  margin-bottom: 40px;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 2rem;
}

/* .blackBackGrond {
    background: rgba(0, 0, 0, 0.6);
  } */

.midStyle {
  color: #ff3bff;
}
.infoHeading {
  font-size: 4rem;
  font-weight: 700;
  /* font-family: 'Clash Grotesk', sans-serif !important; */
  /* border: 20px solid red; */
}

.infoPara {
  margin-top: 1rem;
  text-align: center;
  font-family: "Cabinet grotesk", sans-serif !important;
  line-height: 33px;
  /* font-size: 20px; */
  width: 90%;
  /* border: 20px solid red; */
}

.gradienText {
  background-image: linear-gradient(
    120deg,
    #ff3bff 30.4%,
    #ecbfbf 50.74%,
    #5c24ff 70.27%
  );

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  background-size: 150% 150%;
  -webkit-animation: gradientChange 7s linear infinite;
          animation: gradientChange 7s linear infinite;
}
.NormalText {
  color: white;
  font-family: " Clash grotesk", sans-serif !important;
}
@media (max-width: 768px) {
  .infoHeading {
    width: 90%;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem; /* Adjust this value to your desired smaller font size */
  }
}

