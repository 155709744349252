@import url('https://fonts.cdnfonts.com/css/cabinet-grotesk');

body {
  margin: 0;
  font-family: 'Clash Grotesk', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  overflow-x: hidden;
  /* overflow: hidden; */
}

button {
  cursor: pointer;
}

code {
  font-family: 'Clash Grotesk', sans-serif !important;
}

.text-gray {
  color: #6f6868;
}

.text-bold {
  font-weight: 600;
}

.toast-red {
  background-color: red;
}

.toast {
  color: rgb(27, 27, 27);
  background: white;
  border-radius: .5rem;
  display: flex;
  flex-direction: column;
  font-weight: 600;

}

.toast-btn {
  background: rgba(197, 90, 239);
  color: white;
  border: none;
  padding: .35rem .75rem;
  border-radius: .25rem;
  margin-top: .5rem;
  cursor: pointer;
  font-weight: 600;
  font-size: clamp(.75rem, 1vw, .8rem);
}

.toast-btn:hover {
  background: rgba(197, 90, 239, .8);
}

.toast-btn.close {
  color: black;
  background: #9490902b;
}

.toast-btn.close:hover {
  background: #b7afaf27;
}

.toast-btn-group {
  gap: .5rem;
  display: flex;
  justify-content: flex-end;
}