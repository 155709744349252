.card {
  position: relative;
  /* padding-top: 24px; */
  /* padding-bottom: 21px; */
  /* padding-left: 72px; */
  /* padding-right: 72px; */
  width: 220px;
  height: 100px;
  display: flex;
  white-space: nowrap;
  align-items: center;
  /* border-radius: 24px; */
  /* border-radius: 0px 25px; */
  flex-direction: column;
  justify-content: center;
  /* border: 1px solid #FB54F3; */
  background: var(--clr-black-shade-bg);
  border: 1px solid pink;
  border-radius: 20px;
  border: double 2px transparent;
  background-image: linear-gradient(black, black),
    linear-gradient(120deg, #ff3bff, #ecbfbf, #5c24ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.upper-box-group .card {
  width: 340px;
  /* Add any other specific styles you need */
}

/* child */
.border-wrapper {
  overflow: hidden;
  position: relative;
  /* Clip the gradient border within the border radius */
}

/* parent */
.border-wrapper {



  /* padding: 2rem; */
  /* Ensure the entire border area is covered by the gradient */
  /* Optional: Add padding to make space for content */
}

.card-heading {
  font-size: 1.1rem;
  color: #fb54f3;
}

.card-value {
  font-size: 1.3rem;
  color: white;
  text-align: center;
}

.card .content {
  gap: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 998px) {
  .card {
    width: 170px;
  }

  .card-heading {
    font-size: 0.8rem;
  }

  .card-value {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 425px) {
  .card {
    width: 150px;
  }

  .card-heading {
    font-size: 0.8rem;
  }

  .card-value {
    font-size: 0.9rem;
  }
}