.bg-image {
  inset: 0;
  z-index: -1;
  width: 100vw;
  opacity: 0.8;
  /* height: 100vh; */
  position: fixed;
  background: url("../assets/images/bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.amount {
  width: 90%;
  display: flex !important;
  margin: auto;
}

.amount>button {
  padding: 0px;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-container {
  --min-h: 350px;
  width: 100%;
  /* border: 20px solid rebeccapurple; */
  display: flex;
  padding: 10rem 3rem 5rem 3rem;
  position: relative;
  align-items: flex-end;
  justify-content: center;
  background: transparent;
  /* min-height: 100vh;      */
}

.token-container-wrapper {
  width: min(410px, 100%);
  min-height: var(--min-h);
  height: auto;
  position: relative;
  /* border: 20px solid white; */
}

.upper-box-group {
  /* border: 10px solid white; */
  top: -100px;
  width: 260%;
  left: 50%;
  translate: -50% 0;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: space-between;
  /* border: 10px solid orange; */
}

.left-box-group,
.right-box-group {
  display: grid;
  --distance: -80%;
  position: relative;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  justify-content: center;
  align-items: center;
  /* border: 10px solid red; */
}

.left-box-group {
  left: var(--distance);
  height: 100%;
  position: absolute;
}

.right-box-group {
  height: 100%;
  right: var(--distance);
  position: absolute;
}

.left-box-group .card::before,
.right-box-group .card::before {
  content: "";
  height: 2px;
  width: 100%;
  position: absolute;
  background: var(--clr-light-green);
}

.left-box-group .card::before {
  left: 100%;
}

.right-box-group .card::before {
  right: 100%;
}

.token-container {
  width: 100%;
  min-height: var(--min-h);
  height: auto;
  gap: 0.75rem;
  /* padding: 1.5em; */
  display: flex;
  text-align: center;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  background: var(--clr-black-shade-bg);
  border: 1px solid pink;
  border-radius: 20px;
  margin-top: 2.5rem;
  border: double 2px transparent;
  background-image: linear-gradient(black, black),
    linear-gradient(120deg, #ff3bff, #ecbfbf, #5c24ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.pol-creater-wrapper {
  width: 100%;
  height: auto;
  /* padding: 1rem 0rem; */
  position: relative;
  /* border-radius: 0 40px; */
  background: var(--clr-light-green);
  border: 1px solid var(--clr-black-shade-bg);
  margin-bottom: 2rem;
  background-color: #00000046;
}

.payment-option-container {
  gap: 1em;
  padding: 1em 2em;
  display: flex;
  flex-direction: column;
  /* border: 5px solid red; */
  width: 100%;
}

.payment-option-container input {
  width: 100%;
  padding: 0.5rem;
  text-align: center;
  border-radius: 1rem;
  background: transparent;
  border: 1px solid #000;
  font-size: 20px;
  font-family: Inter;
  color: rgba(18, 18, 18, 0.88);
}

.payment-option-container input::placeholder {
  color: black;
  font-weight: 400;
}

.payment-opt-btn-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 10px solid black; */
  /* background-color: linear-gradient(120deg, #ff3bff, #ecbfbf, #5c24ff); */
}

.gradientButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 10px solid black; */

  /* background-color: linear-gradient(120deg, #ff3bff, #ecbfbf, #5c24ff); */
}

.gradientButton .button {
  width: 100%;
  border: 2px solid black;
}

.gradient-border {
  border: 1px solid pink;
  border-radius: 20px;
  border: double 2px transparent;
  background-image: linear-gradient(black, black),
    linear-gradient(120deg, #ff3bff, #ecbfbf, #5c24ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  /* height: 20px; */
}

.white-border {
  border: 2px solid white;
  border-radius: 20px;
}

.payment-opt-btn-group button {
  cursor: pointer;
  border: none;
  padding: 0.1rem;
  font-size: 0.7rem;
  height: 35px;
  /* background-color: linear-gradient(120deg, #ff3bff, #ecbfbf, #5c24ff); */
  /* border-radius: 0 10px; */
  color: white;
  /* color: var(--clr-light-green);
  background: var(--clr-black-shade-bg);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
}

.connect-wallet-btn {
  /* margin-top: 10px; */
  justify-self: flex-end;
}

.wrapper-container {
  top: 50%;
  left: 50%;
  width: 102%;
  height: 102%;
  display: flex;
  padding: 1.5rem;
  position: absolute;
  align-items: center;
  border-radius: 0 40px;
  justify-content: center;
  backdrop-filter: blur(5px);
  transform: translate(-50%, -50%);
  border: 1px solid var(--clr-black-shade-bg);
}

.connect-wallet {
  background: transparent !important;
  border: 2px solid white !important;
  border-radius: 20px !important;
  width: 100% !important;
}

.connect-wallet button {
  width: 100% !important;
  background: transparent !important;
  text-align: center !important;
}

.pol-creater {
  width: 100%;
  height: 100%;
  border-radius: 0 40px;
  background: var(--clr-black-shade-bg);
}

.pol-creater .container {
  gap: 0.5rem;
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.pol-creater .container input {
  font-size: 0.8rem;
  padding: 0.5rem 1rem;
  border-radius: 0 30px;
  background: transparent;
  color: var(--clr-light-green);
  border: 1px solid var(--clr-light-green);
}

.pol-creater .container input::placeholder {
  font-size: 0.6rem;
  text-align: center;
  color: var(--clr-light-green);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

/* Only for mob */

.tab-pannel {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 12px;
  border: 1px solid #CBD7FF;
}

.tab {
  color: white;
  border: none;
  font-weight: 600;
  border-radius: 12px;
  padding: 1rem 2rem;
  background: transparent;
  font-size: clamp(1rem, 1.5vw, 1rem);
}

.tab.btn-1 {
  color: black;
  background: #FFCBFD;
}

.tab.btn-2 {
  color: black;
  background: #b892ef;
}

.data-info {
  color: white;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: .5rem;
  border: 1px solid #CBD7FF;
  border-radius: 12px;
  background: black;
}

.row {
  font-weight: 600;
  display: grid;
  font-size: clamp(0.8rem, 1.5vw, 1rem);
  grid-template-columns: 1fr 1fr;
}

.pol-creater .container .confirm-btn {
  padding: 0.5rem;
  font-size: 0.7rem;
  background: var(--clr-light-green);
}

.mob {
  display: none;
}

.btn-group-mob {
  display: none;
}



@media only screen and (max-width: 998px) {
  .btn-group-desk {
    display: none;
  }

  .btn-group-mob {
    display: grid;
  }

  .mob {
    display: grid;
  }

  .gradient-border {
    background-image: initial;
    border: 1px solid #CBD7FF !important;
  }

  .border-tab-1 {
    border-color: #B892EF !important;
  }

  .upper-box-group,
  .left-box-group,
  .right-box-group {
    display: none;
  }

  .mob-claim-btn {
    margin-top: 1rem;
    display: grid;
    gap: 1rem;
  }


  .infoPara {
    line-height: 30px !important;
  }

  .connect-wallet,
  .payment-option-container input {
    border-radius: 12px !important;
  }

  .main-container {
    padding: 3rem 1rem;
    align-items: center;
  }

  .token-container-wrapper {
    gap: 1rem;
    display: grid;
  }

  .token-container {
    margin-top: 0;
  }

  .payment-option-container {
    padding: 5% 7%;
  }


  .token-container>button,
  .token-container>input {
    border-radius: 12px !important;
  }
}

@media only screen and (max-width: 768px) {

  .main-container {
    align-items: center;
    padding: 1.5rem;
  }

}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 350px) {}


/* new CSS for mobile */
@media only screen and (max-width: 998px) {}