.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0;
  width: 100%;
  max-width: 1100px;
  height: 100%;
  margin-bottom: 40px;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 2rem;
}

/* .blackBackGrond {
    background: rgba(0, 0, 0, 0.6);
  } */

.midStyle {
  color: #ff3bff;
}
.infoHeading {
  font-size: 4rem;
  font-weight: 700;
  /* font-family: 'Clash Grotesk', sans-serif !important; */
  /* border: 20px solid red; */
}

.infoPara {
  margin-top: 1rem;
  text-align: center;
  font-family: "Cabinet grotesk", sans-serif !important;
  line-height: 33px;
  /* font-size: 20px; */
  width: 90%;
  /* border: 20px solid red; */
}

.gradienText {
  background-image: linear-gradient(
    120deg,
    #ff3bff 30.4%,
    #ecbfbf 50.74%,
    #5c24ff 70.27%
  );

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  background-size: 150% 150%;
  animation: gradientChange 7s linear infinite;
}
.NormalText {
  color: white;
  font-family: " Clash grotesk", sans-serif !important;
}
@media (max-width: 768px) {
  .infoHeading {
    width: 90%;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem; /* Adjust this value to your desired smaller font size */
  }
}
